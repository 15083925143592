@mixin plyr-theme($background, $color) {
  .plyr input[type='range']::-ms-fill-lower {
    background: $background !important;
  }

  .plyr input[type='range']:active {
    &::-webkit-slider-thumb {
      background: $background !important;
    }
    &::-moz-range-thumb {
      background: $background !important;
    }
    &::-ms-thumb {
      background: $background !important;
    }
  }

  .plyr--video .plyr__controls button.tab-focus:focus,
  .plyr--video .plyr__controls button:hover {
    background: $background !important;
    color: $color !important;
  }

  .plyr--audio .plyr__controls button.tab-focus:focus,
  .plyr--audio .plyr__controls button:hover {
    background: $background !important;
    color: $color !important;
  }

  .plyr__play-large {
    background: $background !important;
    color: $color !important;
  }

  .plyr__progress--played,
  .plyr__volume--display {
    color: $background !important;
  }
}
