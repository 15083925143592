// Button groups
//

@if not $material-style {
  :not(.btn-group) > .btn-group > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .btn-group > .btn-group:first-child > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .input-group-prepend > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .input-group-append > .btn:not(.btn-default):not([class*="btn-outline-"]):first-child {
    @include ltr-style {
      border-left-color: transparent !important;
    }
    @include rtl-style {
      border-right-color: transparent !important;
    }
  }

  :not(.btn-group) > .btn-group > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
  .btn-group > .btn-group:last-child > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
  :not(.btn-group) > .btn-group > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]),
  .btn-group > .btn-group:last-child > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]),
  .input-group-prepend > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
  .input-group-append > .btn:not(.btn-default):not([class*="btn-outline-"]):last-child,
  .input-group-prepend > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]),
  .input-group-append > .dropdown-toggle:not(.btn-default):not([class*="btn-outline-"]) {
    @include ltr-style {
      border-right-color: transparent !important;
    }
    @include rtl-style {
      border-left-color: transparent !important;
    }
  }
}

// *******************************************************************************
// * Sizing

.btn-group-xs > .btn {
  @extend .btn-xs;
}

.btn-group-sm > .btn {
  font-size: $input-btn-font-size-sm;
}

.btn-group-lg > .btn {
  font-size: $input-btn-font-size-lg;
}

.btn-group-xl > .btn {
  @extend .btn-xl;
}

// *******************************************************************************
// * Split button

.dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.input-group-lg .btn + .dropdown-toggle-split,
.btn-xl + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split {
  padding-right: .7em;
  padding-left: .7em;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.input-group-sm .btn + .dropdown-toggle-split {
  padding-right: .6em;
  padding-left: .6em;
}

.btn-xs + .dropdown-toggle-split,
.btn-group-xs > .btn + .dropdown-toggle-split {
  padding-right: .5em;
  padding-left: .5em;
}

// *******************************************************************************
// * RTL

@include rtl-only {
  .btn-group .btn:not(.btn-round) {
    @include border-radius($border-radius !important);
  }

  .btn-group .btn-xs:not(.btn-round),
  .btn-group-xs .btn:not(.btn-round) {
    @include border-radius($border-radius-xs !important);
  }

  .btn-group .btn-sm:not(.btn-round),
  .btn-group-sm .btn:not(.btn-round) {
    @include border-radius($border-radius-sm !important);
  }

  .btn-group .btn-lg:not(.btn-round),
  .btn-group-lg .btn:not(.btn-round) {
    @include border-radius($border-radius-lg !important);
  }

  .btn-group .btn-xl:not(.btn-round),
  .btn-group-xl .btn:not(.btn-round) {
    @include border-radius($border-radius-xl !important);
  }

  .btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
      margin-right: -$input-btn-border-width;
      margin-left: 0;
    }

    > .btn:first-child {
      margin-left: 0;
    }

    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      @include border-left-radius(0 !important);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      @include border-right-radius(0 !important);
    }
  }

  .btn-group-vertical {
    .btn:not([class*=btn-outline]) {
      border-right-color: transparent !important;
      border-left-color: transparent !important;
    }

    > .btn:not(:first-child):not(:last-child),
    > .btn-group:not(:first-child):not(:last-child) > .btn {
      border-radius: 0 !important;
    }

    > .btn-group:first-child:not(:last-child) > .btn {
      @include border-bottom-radius(0 !important);
    }

    > .btn-group:last-child:not(:first-child) > .btn {
      @include border-top-radius(0 !important);
    }
  }
}
