@import "~@ng-select/ng-select/themes/default.theme.css";

app-root:empty + .wrapper {
  display: block;
  z-index: 1;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px !important;
  height: 30px !important;
  padding: 6px 0px !important;
  border-radius: 60rem !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}


.wrapper {
  display: none;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  max-width: 400px;
  height: 400px;


  h1 {
    position: absolute;
    margin: auto;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 85px;
    height: 40px;
    text-transform: uppercase;
    text-align: left;
    text-shadow: 0 0 20px #004993;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-weight: lighter;
    color: #004993;
    span {
      display: none;
    }
    &::after {
      animation: txt 1s infinite;
      content: "";
    }
  }
}

.loader-outer {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 30px;
  border-radius: 20px;
  border-bottom: 1px solid #303030;
  background: #151515;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 2;
  .loader-inner {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: -100px;
    width: auto;
    height: 58px;
    color: #004993;
    text-align: center;
    text-shadow: 0 0 20px #004993;
    font-size: 44px;
    z-index: 3;
    animation: load 1s infinite linear;
  }
}



@keyframes load {
  0% {
    left: -100px;
  }
  100% {
    left: 220px;
  }
}

@keyframes txt {
  0% {
    content: "LOADING";
  }
  35% {
    content: "LOADING.";
  }
  65% {
    content: "LOADING..";
  }
  100% {
    content: "LOADING...";
  }
}



@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

.invalid-feedback2 {
  margin-top: 0.25rem;
  color: #d9534f;
}


.form-control.ng-select {
  border: none;
  padding: 0;
}

.state-error {
  border: 1px solid #ff0000!important;
}

.block-ui-generar-pdf .block-ui-wrapper {
  background: rgba(255, 217, 80, 0.8);
}
.block-ui-generar-pdf .block-ui-template {
  top: 50%;
}

.block-ui-enviar-moviliza .block-ui-wrapper {
  background: rgba(41, 255, 19, 0.8);
}
.block-ui-enviar-moviliza .block-ui-template {
  top: 50%;
}

.text-orange{
  color: #d35400!important;
}
.bg-orange{
  background-color: #d35400!important;
}
.block-ui-certificar .block-ui-wrapper {
  background: rgba(255, 255, 255, .8);
  color: #444;
}
.block-ui-certificar .block-ui-template {
  top: 50%;
}

.img-magnifier-container {
  position:relative;
}
.img-magnifier-container:hover .img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  z-index: 300;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  display:block;
}
.img-magnifier-glass {
  display:none;
}

// Remove outline on desktops
@media(min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

.dropdown-menu-right {
  right: auto !important;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Tooltip and popover animations

@include keyframes(ngTooltipAnimation) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

ngb-tooltip-window {
  animation: ngTooltipAnimation .25s;
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .5; }
}
@include keyframes(ngMaterialModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .2; }
}
@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@include keyframes(ngRtlSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }

  [dir=rtl] &.modal-slide .modal-dialog {
    animation: ngRtlSlideModalAnimation .15s ease-out;
  }
}

.material-style ngb-modal-backdrop.modal-backdrop {
  opacity: .2;
  animation: ngMaterialModalBackdropAnimation .15s ease-out;
}

// Prevent horizontal scrollbar
[dir=rtl] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header > * > .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  vertical-align: inherit;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header > * > .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

/** Novaip ip custom properties */

/** Color helpers */
$primary: #930E11;

button.btn-default {
  border: 1px solid !important;
}

.icon-create-edit{
  color: #007ec1!important;
}

.icon-delete{
  color: #db534b!important;
}

/** Forms helpers */
.custom-select,
input[type="text"] {
  border: 1px solid rgba(24, 28, 33, 0.18) !important;
}

/** flex box helpers */
.flex {
  display: flex;
}
.column {
  flex-direction: column !important;
}
.space-between {
  justify-content: space-between !important;
}

.margin-right-icon {
  margin-right: 2px !important;
}


.sidenav.bg-white .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle, .sidenav.bg-white .sidenav-item.active > .sidenav-link{
 color: #b6b8b9 !important;
}


.table {
  border-spacing: 0px;
  vertical-align: top;
  td {
    vertical-align: top;
    padding: 2px 10px;
  }
  tr:first-child td:first-child {
    border-left: 1px solid #888;
  }
}


.tablaprueba{
  table {

    border-spacing: 0px;
    vertical-align: top;
  }
  table td {
    /* vertical-align: top; */
    padding: 2px 10px;
  }
  table tr:first-child td:first-child {
    border-left: 1px solid #888;
  }
  td.key:after {
    content: ':';
  }
  td.key {
    text-align: right;
  }
  td.key {
    padding-right: 1px;
    border: 0 none;
  }
  td.val {
    color:#000;
    min-width: 100%;
    border: 0 none;
  }
  td.key, td.val {
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
  }
  td.val {
    border-right: 1px solid #bbb;
  }
  td.children {

    border-right: 2px solid #888;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
  }
}
