// Input groups
//

.input-group .custom-select {
  flex: 0 1 auto;
  width: auto;
}

.input-group-text {
  background-clip: padding-box;
}

// *******************************************************************************
// * Material

@if $material-style {
  .input-group-text {
    border-bottom-width: 1px;
  }

  .input-group-text + .input-group-text {
    @include ltr-style {
      padding-left: 0;
    }
    @include rtl-style {
      padding-right: 0;
    }
  }

  .input-group-prepend .input-group-text:first-child {
    @include ltr-style {
      padding-left: 0;
    }
    @include rtl-style {
      padding-right: 0;
    }
  }

  .input-group-prepend .input-group-text:last-child {
    @include ltr-style {
      padding-right: 0;
    }
    @include rtl-style {
      padding-left: 0;
    }
  }

  .input-group-append .input-group-text:last-child {
    @include ltr-style {
      padding-right: 0;
    }
    @include rtl-style {
      padding-left: 0;
    }
  }

  .input-group-append .input-group-text:first-child {
    @include ltr-style {
      padding-left: 0;
    }
    @include rtl-style {
      padding-right: 0;
    }
  }

  .input-group > .form-control:first-child,
  .input-group > .custom-select:first-child,
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select {
    &:not([class*='px-']):not([class*='pl-']) {
      @include ltr-style {
        padding-left: 0;
      }
      @include rtl-style {
        padding-right: 0;
      }
    }
  }

  .input-group > .form-control:last-child:not([class*='px-']):not([class*='pr-']) {
    @include ltr-style {
      padding-right: 0;
    }
    @include rtl-style {
      padding-left: 0;
    }
  }

  .input-group > .custom-select:last-child:not([class*='px-']):not([class*='pr-']) {
    @include ltr-style {
      padding-right: $custom-select-padding-x !important;
      background-position: right center !important;
    }
    @include rtl-style {
      padding-left: $custom-select-padding-x !important;
      background-position: left center !important;
    }
  }
}

// *******************************************************************************
// * RTL

@include rtl-only {
  // Reset input border radius

  .input-group .form-control,
  .input-group .custom-select,
  .input-group .custom-file-label,
  .input-group .custom-file-label::before {
    @include border-radius($input-border-radius !important);
  }

  .form-control,
  .custom-select {
    .input-group-sm & {
      @include border-radius($input-border-radius-sm !important);
    }

    .input-group-lg & {
      @include border-radius($input-border-radius-lg !important);
    }
  }

  // Reset addon border radius

  .input-group > .input-group-prepend,
  .input-group > .input-group-append {
    .btn,
    .input-group-text {
      @include border-radius($border-radius !important);
    }
  }

  .input-group-sm > .input-group-prepend,
  .input-group-sm > .input-group-append {
    .btn,
    .input-group-text {
      @include border-radius($border-radius-sm !important);
    }
  }

  .input-group-lg > .input-group-prepend,
  .input-group-lg > .input-group-append {
    .btn,
    .input-group-text {
      @include border-radius($border-radius-lg !important);
    }
  }

  // Remove border radius

  .input-group .form-control,
  .input-group .custom-select {
    &:not(:last-child) {
      @include border-left-radius(0 !important);
    }

    &:not(:first-child) {
      @include border-right-radius(0 !important);
    }
  }

  .input-group .custom-file {
    &:not(:last-child) .custom-file-label,
    &:not(:last-child) .custom-file-label::before {
      @include border-left-radius(0 !important);
    }

    &:not(:first-child) .custom-file-label,
    &:not(:first-child) .custom-file-label::before {
      @include border-right-radius(0 !important);
    }
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .input-group > .input-group-append > .b-dropdown > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .b-dropdown > .btn,
  .input-group > .input-group-prepend:first-child > .b-dropdown:not(:first-child) > .btn,
  .input-group > .input-group-prepend:first-child > .b-dropdown > .dropdown-toggle-split {
    @include border-right-radius(0 !important);
  }

  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .input-group > .input-group-prepend > .b-dropdown > .btn,
  .input-group > .input-group-append:not(:last-child) > .b-dropdown > .btn,
  .input-group > .input-group-append:last-child > .b-dropdown:not(:last-child):not(.dropdown-toggle) > .btn,
  .input-group > .input-group-append:last-child > .b-dropdown:not(:last-child) > .dropdown-toggle-split {
    @include border-left-radius(0 !important);
  }

  // Margins

  .input-group .form-control,
  .input-group .custom-select,
  .input-group .custom-file {
     + .form-control,
     + .custom-select,
     + .custom-file {
      margin-right: -$input-border-width;
      margin-left: 0;
    }
  }

  .input-group-prepend,
  .input-group-append {
    .btn + .btn,
    .btn + .input-group-text,
    .input-group-text + .input-group-text,
    .input-group-text + .btn {
      margin-right: -$input-border-width;
      margin-left: 0;
    }
  }

  .input-group-prepend {
    margin-right: 0;
    margin-left: -$input-border-width;
  }

  .input-group-append {
    margin-right: -$input-border-width;
    margin-left: 0;
  }
}
